@import './../../../style.scss';
@import './../../assets/scss/common';

#wrapper {
    display: flex;
    flex-direction: column;
    .wrapper-content {
        background-color: $color-baby-powder;
        padding: 8px 0;
        #content-wrapper {
            background-color: inherit;
            padding-left: 0.5rem;
            overflow-x: clip;
            .layout-header {
                padding: 1.75rem 1.5rem 15px;
                margin-bottom: 15px;
                width: 100%;
                background-color: $color-baby-powder;
                color: $color-charcoal;
                &.sticky {
                    position: sticky;
                    top: 0;
                    z-index: $z-index-sticky-header;
                    background-color: $color-baby-powder;
                }
                .page-title {
                    font-size: 32px;
                    font-weight: 600;
                    line-height: 130%;
                }
            }
        }
    }
}
