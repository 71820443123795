@import '../../assets/scss/common.scss';
@import '../../../style.scss';

.rule {
  position: relative;
  margin-bottom: 0;

  &>.rule-group {
    display: flex;
    flex-wrap: wrap;
    padding-right: 200px;

    &>.conj {
      padding-right: 10px;
      padding-left: 10px;
      line-height: 30px;
    }
  }

  &.funnel-mode {
    .rule-group {
      padding-right: 50px;
    }
  }

  .field-col {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    padding: 0 5px;
    margin-bottom: 10px;

    &>.conj {
      font-size: 10px;
      display: none;
      margin-right: 10px;
    }
  }

  .value-field-container {
    position: relative;
    flex-basis: 180px;
    flex-shrink: 0;
    padding-right: 35px;

    &.with-unit {
      flex-basis: 130px;
      padding-right: 0;
    }

    &>.btn-delete {
      padding: 0;
      position: absolute;
      right: 15px;
      box-shadow: none;
    }
  }

  .value-field-container+.value-field-container {
    &>.conj {
      display: inline-block;
      align-items: center;
    }
  }

  &>.actions {
    position: absolute;
    right: 0;
    top: -3px;

    &>span {
      margin-right: 10px;
      position: relative;
      bottom: -1px;
    }
  }

  &+.filter-separator {
    margin-top: 5px;

    &:after {
      border-bottom-style: dashed;
    }
  }

  &.condition {
    &>.rule-group {
      padding-left: 70px;

      &>.conj {
        position: absolute;
        left: 0;
      }
    }

    .add-condition-btn-container {
      display: none;
    }

    &:nth-child(1)>.rule-group {
      padding-left: 70px;
    }

    &:last-child {
      .add-condition-btn-container {
        display: block;
      }
    }
  }
}

.filter-separator {
  font-size: 9px;
  position: relative;
  height: 20px;
  margin: 0 0 10px 5px;
  line-height: 20px;

  &>span {
    position: absolute;
    left: 0;
    top: 0;
  }

  &:after {
    content: " ";
    display: block;
    border-bottom: 1px solid #b7b9cc;
    position: absolute;
    top: 10px;
    left: 30px;
    right: 0;
    opacity: .5;
  }
}

.filter-panel {
  width: 100%;
  .filter-row {
    .row-header {
      font-size: 13px;
      position: relative;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &>.label {
        &>span {
          margin-left: 5px;
        }
      }
    }
  }
}

.page-container {
  margin-top: 15px;
  margin-bottom: 10px;
  border-radius: 2px;

  &>.container-header {
    font-size: .8rem;
    border-bottom: 1px solid #ddd;
    padding: 10px 15px;
    margin: 0;

    &>.btn {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &>.container-body {
    padding: 15px 15px 10px 15px;
  }
}

.upgrade-flag {
  cursor: pointer;
  svg {
    color: $color-dark-orange;
    background: white;
    border-radius: 15px;
  }
}

// Attribute selector styles
.attribute-selector {
  position: relative;
  min-width: 165px;
  cursor: default;

  .selected-attribute {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    padding: 4px 8px;
    border: 1px solid #d1d3e2;
    border-radius: 4px;
    background: white;
    user-select: none;
    color: #6e707e; // keep the color consistent with other legacy selectors for now
    font-size: 14px;
    .label-group {
      display: flex;
      align-items: center;
      gap: 5px;
      svg {
        width: 14px;
        height: 14px;
      }
    }

    .placeholder {
      color: #6e707e; // keep the color consistent with other legacy selectors for now
    }

    svg {
      width: 10px;
    }
  }

  .attribute-dropdown {
    position: fixed;
    width: 933px;
    max-height: 500px;
    background: white;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    z-index: $z-index-attribute-dropdown;
    transform-origin: top left; /* Add transform origin for smooth transitions */
    transition: transform 0.2s ease; /* Add transition for smooth positioning changes */
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px;

    @media screen and (max-width: 1280px) {
      width: min(650px, 90vw);
    }
    @media screen and (max-width: 1015px) {
      width: min(380px, 90vw);
    }

    .categories-container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(270px, 1fr)); /* Adjust min-width as needed */
      gap: 24px;
      overflow-y: auto;
      max-height: calc(100vh - 250px); /* Account for search bar, padding, and some spacing */
      .attribute-category {
        width: 270px;
        .category-header {
          font-weight: 700;
          color: $color-aluminum;
          font-size: 16px;
          margin-bottom: 12px;
          text-transform: none;
          padding: 0 6px;
        }

        .attribute-option-cell {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          gap: 3px;
          .attribute-option {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 6px 9px;
            cursor: pointer;
            font-size: 14px;
            border-radius: 3px;
            color: $color-charcoal;
            flex: 1;

            .option-icon {
              display: flex;
              align-items: center;
              svg {
                width: 15px;
                height: 15px;
              }
            }
            
            &:hover {
              background: $color-white-smoke;
            }
          }

          .upgrade-button {
            flex-shrink: 0;
          }

          .selected {
            background: $color-neon-blue;
            color: white;

            &:hover {
              background: $color-neon-blue-hover;
              color: white;
            }
          }
        }
      }
      .user {
        width: 160px;
      }
      .replay-click {
        width: 220px;
      }
    }
    .user {
      grid-template-columns: repeat(auto-fit, minmax(160px, 1fr)); /* Adjust min-width as needed */
    }
    .replay-click {
      grid-template-columns: repeat(auto-fit, minmax(220px, 1fr)); /* Adjust min-width as needed */
    }
  }
  >.replay-click {
    width: 250px;
    padding: 15px;
  }
}