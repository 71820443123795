@import '../../../assets/scss/common';

.ui-button {
    display: flex;
    align-items: center;
    border: none;
    cursor: pointer;
    justify-content: center;
    line-height: 100%;
    gap: 9px; // Space between icon and text
    min-width: fit-content;
    text-wrap: nowrap;

    &:focus, &:active {
        outline: 1px solid $color-alice-blue;
    }

    // button colors
    &.button-default {
        border: 1px solid $color-anti-flash;   
        background-color: $color-white-smoke;
        color: $color-charcoal;
        svg {
            fill: $color-charcoal;
        }
        &:hover {
            background: $color-baby-powder;
        }
    }

    &.button-plain-default {
        background-color: transparent;
        color: $color-marengo;
        svg {
            fill: $color-marengo;
        }
    }

    &.button-plain-dark {
        background-color: transparent;
        color: $color-charcoal;
        svg {
            fill: $color-charcoal;
        }
    }

    &.button-primary {
        background-color: $color-neon-blue;
        border: 1px solid $color-neon-blue;
        color: white;
        &:hover {
            background-color: $color-neon-blue-hover;
        }
    }

    &.button-outlined-primary {
        border: 1px solid $color-neon-blue;
        background-color: white;
        color: $color-neon-blue;
        &:hover {
            border: 1px solid $color-neon-blue-hover;
            color: $color-neon-blue-hover;
        }
    }

    &.button-secondary {
        background: $color-alice-blue;
        color: $color-charcoal;
        &:hover {
            background-color: $color-aluminum;
        }
    }

    &.button-aluminum {
        background: $color-aluminum;
        color: white;
        &:hover {
            background-color: $color-aluminum;
        }
    }

    &.button-plain {
        background-color: transparent;
        color: $color-neon-blue;
        &:hover {
            color: $color-neon-blue-hover;
        }
    }

    &.button-outlined-plain {
        border: 1px solid $color-anti-flash;
        background: white;
        color: $color-neon-blue;
        &:hover {
            border: 1px solid $color-alice-blue;
            color: $color-neon-blue-hover;
        }
    }

    &.button-success {
        background-color: $color-caribbean-green;
        color: white;
        &:hover {
            background-color: $color-caribbean-green-hover;
        }
    }

    &.button-orange {
        background-color: $color-dark-orange;
        color: white;
        &:hover {
            background-color: $color-dark-orange-hover;
        }
    }

    &.button-outlined-orange {
        border: 1px solid $color-dark-orange;
        color: $color-dark-orange;
        background: none;
        &:hover {
            border-color: $color-dark-orange-hover;
            color: $color-dark-orange-hover;
        }
    }

    &.button-plain-orange {
        color: $color-dark-orange;
        background: none;
        border: 1px solid transparent;
        &:hover {
            border: 1px solid $color-dark-orange-hover;
            color: $color-dark-orange-hover;
        }
    }

    &.button-ITgradient {
        background: $insightech-gradient;
        color: white;
        &:hover {
            background: 
                linear-gradient(90deg, #4a4afa 0%, #05cf9b 60%);
        }
    }

    &.button-disabled {
        border: 1px solid $color-alice-blue;
        background-color: white;
        color: $color-ghost;
        cursor: default;
        &:hover {
            border: 1px solid $color-ghost;
        }
    }

    // button sizes
    &.button-medium {
        font-size: 16px;
        font-weight: 600;
        padding: 12px 30px;
        border-radius: 6px;
        svg {
            width: 20px;
            height: 20px;
        }
    }

    &.button-small {
        font-size: 14px;
        font-weight: 600;
        padding: 10px 20px;
        border-radius: 6px;
    }

    &.button-chip {
        font-size: 13px;
        font-weight: 500;
        padding: 9px 18px;
        border-radius: 6px;
        line-height: normal;
        svg {
            width: 20px;
            height: 20px;
        }
    }

    &.button-flag {
        font-size: 11px;
        font-weight: 600;
        padding: 3px 6px;
        border-radius: 3px;
        line-height: 14px;
        white-space: nowrap;
    }

    &.button-tag {
        font-size: 9px;
        font-weight: 600;
        padding: 1px 6px;
        border-radius: 1px;
        line-height: 14px;
        border: none;
        white-space: nowrap;
        cursor: not-allowed;
        pointer-events: none;
    }

    &.button-badge {
        font-size: 9px;
        font-weight: 600;
        padding: 0px 6px;
        border-radius: 5px;
        line-height: 14px;
        border: none;
        white-space: nowrap;
        cursor: not-allowed;
        pointer-events: none;
    }

    .button-dropdown-arrow {
        margin-left: 10px;
        svg {
            width: 10px;
            height: 16px;
            fill: $color-cadet-grey;
        }
    }

    &.read-only {
        cursor: not-allowed;
        pointer-events: none; // Ensures the button does not receive click events
    }

    // button states
    &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: none;
    }
}
